import React from 'react'
import { useTranslation } from 'react-i18next';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion';

// Icon
import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowIcon';

// Components
import TournamentPlayerForm from './TournamentPlayerForm';

function TournamentPlayerListForm({
  value,
  error,
  onChange,
  handleIdentityFile,
  teamMembers,
  tournament
}) {
  const { t } = useTranslation();

  const AccordionHeading = ({ heading }) => {
    return (
      <AccordionItemHeading>
        <AccordionItemButton>
          <AccordionItemState>
            {({ expanded }) => (
              <div className={`flex flex-row p-4 justify-between items-center border-b-2 border-gray hover:bg-gray-200 ${expanded && "bg-gray-200"}`}>
                <div>
                  <p className='text-sm sm:text-base font-bold'>
                    {heading}
                  </p>
                </div>
                <div>
                  <KeyboardArrowDownIcon
                    className={`h-3 sm:h-4 lg:h-5 w-3 sm:w-4 lg:w-5 fill-black ml-auto transition-transform duration-200 ease-in-out ${expanded && "rotate-180"
                      }`}
                  />
                </div>
              </div>)}
          </AccordionItemState>
        </AccordionItemButton>
      </AccordionItemHeading>
    )
  }

  return (
    <section>
      <div className='pt-3 space-y-8'>
        <p className='text-center text-center text-lg md:text-xl xl:text-2xl font-bold'>
          {t('playerList')}
        </p>
        <Accordion preExpanded={[0]} allowZeroExpanded className='transition-all duration-200'>
          {
            teamMembers && teamMembers.map((item, i) => {
              const playerIdentifier = i + 1
              return (
                <AccordionItem uuid={i}>
                  <AccordionHeading heading={`Player ${playerIdentifier}`} />
                  <AccordionItemPanel className='p-4 transition-height duration-200 ease-in-out'>
                    <TournamentPlayerForm
                      textInputName1={`team_members[${i}].name_player_${playerIdentifier}`}
                      valueInput1={value['team_members'][i][`name_player_${playerIdentifier}`]}
                      errorInput1={error && error['team_members'] && error['team_members'][i][`name_player_${playerIdentifier}`]}
                      textInputName2={`team_members[${i}].nickname_uid_player_${playerIdentifier}`}
                      valueInput2={value['team_members'][i][`nickname_uid_player_${playerIdentifier}`]}
                      errorInput2={error && error['team_members'] && error['team_members'][i][`nickname_uid_player_${playerIdentifier}`]}
                      selectName={`team_members[${i}].role_player_${playerIdentifier}`}
                      valueSelectInput={value['team_members'][i][`role_player_${playerIdentifier}`]}
                      errorSelectInput={error && error['team_members'] && error['team_members'][i][`role_player_${playerIdentifier}`]}
                      fileInputName={`team_members[${i}].identity_file_player_${playerIdentifier}`}
                      valueFileInput={value['team_members'][i][`identity_file_player_${playerIdentifier}`]}
                      errorFileInput={error && error['team_members'] && error['team_members'][i][`identity_file_player_${playerIdentifier}`]}
                      webullInputName={`team_members[${i}].webull_account_player_${playerIdentifier}`}
                      valueWebullInput={value['team_members'][i][`webull_account_player_${playerIdentifier}`]}
                      errorWebullInput={error && error['team_members'] && error['team_members'][i][`webull_account_player_${playerIdentifier}`]}
                      gameUidInputName={`team_members[${i}].game_uid_player_${playerIdentifier}`}
                      valueGameUidInput={value['team_members'][i][`game_uid_player_${playerIdentifier}`]}
                      errorGameUidInput={error && error['team_members'] && error['team_members'][i][`game_uid_player_${playerIdentifier}`]}
                      required={true}
                      onChangeInput={onChange}
                      onChangeFileInput={handleIdentityFile}
                      tournament={tournament}
                    />
                  </AccordionItemPanel>
                </AccordionItem>
              )
            })
          }
          <AccordionItem uuid={teamMembers && teamMembers.length && teamMembers.length + 1}>
            <AccordionHeading heading={t('reservePlayer')} />
            <AccordionItemPanel className='p-4 transition-height duration-200 ease-in-out'>
              <TournamentPlayerForm
                textInputName1={'name_reserve_player'}
                valueInput1={value.name_reserve_player}
                errorInput1={error.name_reserve_player}
                textInputName2={'nickname_uid_reserve_player'}
                valueInput2={value.nickname_uid_reserve_player}
                errorInput2={error.nickname_uid_reserve_player}
                selectName={'role_reserve_player'}
                valueSelectInput={value.role_reserve_player}
                errorSelectInput={error.role_reserve_player}
                fileInputName={'identity_file_reserve_player'}
                valueFileInput={value.identity_file_reserve_player}
                errorFileInput={error.identity_file_reserve_player}
                webullInputName={'webull_account_reserve_player'}
                valueWebullInput={value.webull_account_reserve_player}
                errorWebullInput={error.webull_account_reserve_player}
                gameUidInputName={'game_uid_reserve_player'}
                valueGameUidInput={value.game_uid_reserve_player}
                errorGameUidInput={error.game_uid_reserve_player}
                required={false}
                onChangeInput={onChange}
                onChangeFileInput={handleIdentityFile}
                tournament={tournament}
              />
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  )
}

export default TournamentPlayerListForm