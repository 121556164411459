import React from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';


function TournamentTimeline({ selectedTournamentTimelines }) {
  const { t } = useTranslation();

  return (
    <section>
      <div className='max-w-[90rem] mx-auto px-4 py-6 sm:px-12 sm:py-8 space-y-8'>
        <p className='text-center text-xl sm:text-3xl font-bold'>
          {t('timeLine')}
        </p>
        <div className='grid grid-cols-2 sm:grid-cols-5 justify-items-center gap-2 lg:gap-4 '>
          {selectedTournamentTimelines && selectedTournamentTimelines.length > 0 && selectedTournamentTimelines.map((timeline, i) => {
            return (
              <div className="max-w-lg overflow-auto" key={i}>
                <div className="px-6 py-4 space-y-2">
                  <div className="bg-transparent uppercase text-center text-sm sm:text-base font-bold py-2 px-4 border border-black border-2 rounded-full mb-4">
                    {timeline.title}
                  </div>
                  <div className='text-sm sm:text-base' dangerouslySetInnerHTML={{ __html: timeline.description_html }} />
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default TournamentTimeline