import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/nlb-logo-w.png';

function Navbar({
  openMenu
}) {
  const { t } = useTranslation();
  const dropdownRef = useRef(null)
  const navbarRef = useRef(null)

  const [dropdownOpen, setDropdownOpen] = useState(null)

  const navigation = [
    {
      id: 1,
      label: t('aboutUs'),
      href: "/about",
      dropdownNav: [
        {
          id: 1,
          label: t('whoWeAre'),
          href: "/about"
        },
        {
          id: 2,
          label: t('achievements'),
          href: "/achievements"
        },
        // {
        //   id: 3,
        //   label: t('ourCorePeople'),
        //   href: "/team"
        // },
        {
          id: 3,
          label: t('supportingProfessionals'),
          href: "/supporting-professionals"
        },
        {
          id: 4,
          label: t('whyUs'),
          href: "/why-us"
        },
        // {
        //   id: 3,
        //   label: t('ourCorePeople'),
        //   href: "/team"
        // },
        {
          id: 5,
          label: t('ourCompanyStructure'),
          href: "/company-structure"
        },
        {
          id: 6,
          label: t('ourCompanyOwnership'),
          href: "/company-ownership"
        },
        {
          id: 7,
          label: t('ourLeadership'),
          href: "/leadership"
        }
      ]
    },
    // {
    //   id: 2,
    //   label: t('governance'),
    //   href: '/corporate-secretary',
    //   dropdownNav: [
    //     {
    //       id: 1,
    //       label: t('corporateSecretary'),
    //       href: '/corporate-secretary'
    //     },
    //     {
    //       id: 2,
    //       label: t('internalAudit'),
    //       href: '/internal-audit'
    //     },
    //     {
    //       id: 3,
    //       label: t('auditCommitee'),
    //       href: '/audit-committee'
    //     },
    //     {
    //       id: 4,
    //       label: t('remunerationCommitee'),
    //       href: '/remuneration-committee'
    //     },
    //     {
    //       id: 5,
    //       label: t('riskManagement'),
    //       href: '/risk-management'
    //     },
    //     {
    //       id: 6,
    //       label: t('ourPolicies'),
    //       href: '/policies'
    //     }
    //   ]
    // },
    {
      id: 2,
      label: t('investors'),
      href: '/annual-reports',
      dropdownNav: [
        {
          id: 1,
          label: t('annualReports'),
          href: '/annual-reports'
        },
        // {
        //   id: 2,
        //   label: t('supportInstPro'),
        //   href: '/supporting-institutions'
        // },
        {
          id: 2,
          label: t('financialReports'),
          href: '/financial-reports'
        },
        {
          id: 3,
          label: t('generalMeetShare'),
          href: '/general-meetings'
        },
        {
          id: 4,
          label: t('stockInfo'),
          href: '/stock-information'
        },
        // {
        //   id: 6,
        //   label: t('bondInfo'),
        //   href: '/bond-information'
        // },
        // {
        //   id: 7,
        //   label: t('infoDisclosure'),
        //   href: '/information-disclosure'
        // },
        {
          id: 5,
          label: t('prospectus'),
          href: '/prospectus'
        }
      ]
    },
    {
      id: 3,
      label: t('ourServices'),
      href: "/services/esports-consulting",
      dropdownNav: [
        {
          id: 1,
          label: t('eSportConsulting'),
          href: '/services/esports-consulting',
        },
        {
          id: 2,
          label: t('eSportEventOrganizer'),
          href: '/services/esports-eo'
        },
        {
          id: 3,
          label: t('prodHouse'),
          href: '/services/production-house'
        },
        {
          id: 4,
          label: t('publishingPR'),
          href: '/services/publishing-pr'
        },
        {
          id: 5,
          label: t('eventMarketing'),
          href: '/services/event-marketing'
        },
        {
          id: 6,
          label: t('digitalMarketing'),
          href: '/services/digital-marketing'
        },
        {
          id: 7,
          label: t('eSportTeam'),
          href: '/services/esports-team'
        }
      ]
    },
    {
      id: 4,
      label: t('ourPortfolio'),
      href: "/portfolio",
    },
    {
      id: 5,
      label: t('ourLatestEvents'),
      href: "/events"
    },
    {
      id: 6,
      label: t('tournaments'),
      href: "/tournaments"
    },
    {
      id: 7,
      label: t('corpSocialResp'),
      href: "/corporate-social-responsibility"
    },
    // {
    //   id: 7,
    //   label: t('management'),
    //   href: "/management",
    // },
    // {
    //   id: 8,
    //   label: t('sustainability'),
    //   href: "/",
    // },
    {
      id: 8,
      label: t('contactUs'),
      href: "/contact-us",
    },
  ]

  const handleMouseOver = useCallback((e) => {
    if (dropdownOpen && !dropdownRef?.current?.contains(e.target) && !navbarRef?.current?.contains(e.target)) {
      setDropdownOpen(null)
    }
  }, [dropdownOpen, setDropdownOpen])

  useEffect(() => {
    window.addEventListener('mouseover', handleMouseOver)
    return () => {
      window.removeEventListener('mouseover', handleMouseOver)
    }
  }, [handleMouseOver])

  return (
    <>
      <div className={`sticky top-0 shadow-md transition-all z-40 bg-app-blue`}>
        <div ref={navbarRef} className={`app-nav-container flex justify-between items-center gap-6`}>
          <div>
            <a href='/'>
              <img src={logo} alt="logo" className='h-8 md:h-12' />
            </a>
          </div>
          <div className={`hidden lg:flex md:space-x-12 text-white font-bold`}>
            <ul className='flex items-center sm:text-sm md:text-base'>
              {navigation.map((nav => (
                <li
                  key={nav?.id}
                  onMouseOver={() => setDropdownOpen(nav?.id)}
                  className='px-2 text-center'
                >
                  <a className='hover:border-b' href={nav.href}>{nav.label}</a>
                </li>
              )))}
            </ul>
          </div>
          <button
            className='text-white  font-bold text-sm md:text-base lg:hidden active:border-b-2 active:border-white transition-all'
            onClick={openMenu}
          >
            {t('menuText')}
          </button>
        </div>
        <div
          ref={dropdownRef}
          className={`absolute left-0 right-0 z-30 shadow-md bg-app-blue-3 ${navigation?.[dropdownOpen - 1]?.dropdownNav?.length > 0 ? "block" : "hidden"} transition-all`}
        >
          <ul className='app-main-container py-4 lg:py-6 grid grid-cols-3 gap-4 text-white'>
            {navigation?.[dropdownOpen - 1]?.dropdownNav?.map(nav => (
              <li
                key={nav?.id}
              >
                <a className='hover:border-b transition-all duration-300' href={nav.href}>{nav.label}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  )
}

export default Navbar