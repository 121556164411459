import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Newsletter from 'components/layout/Newsletter';

import ArrowRightIcon from 'assets/icons/ArrowRightIcon';
import mornington from 'assets/images/portfolio/mornington.png';

import {
  generalBrandsLogo,
  gamingPeripheralsLogo,
} from 'utils/constants/data'

import { impacts } from 'utils/constants/data';
import useFetch from 'utils/hooks/useFetch';
import { GET_ALL_PUBLIC_POSTS } from 'api';

function Home() {
  const { data: publicPosts, fetch: getAllPublicPosts } = useFetch(GET_ALL_PUBLIC_POSTS)
  const { t } = useTranslation();

  const esportsConsulting = {
    generalBrands: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          generalBrandsLogo && generalBrandsLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
        <p><b>{t('manyMore')}</b></p>
      </div>
    ),
    gamePeripheralBrands: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          gamingPeripheralsLogo && gamingPeripheralsLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
        <p><b>{t('manyMore')}</b></p>
      </div>
    ),
  }

  useEffect(() => {
    getAllPublicPosts();
  }, [getAllPublicPosts])

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/9072394/pexels-photo-9072394.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='max-h-[60vh] w-full object-cover object-bottom' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-start h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl md:text-4xl max-w-md font-semibold sm:font-bold app-animation-translate-start'>{t('mainBannerText')}</p>
              <button className='text-sm sm:text-base flex items-center gap-2 app-animation-translate-start'>
                <p>{t('learnMore')}</p>
                <ArrowRightIcon className='h-3 sm:h-4 w-3 sm:w-4 fill-white' />
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className='grid grid-cols-1 sm:grid-cols-2'>
        <div>
          <img src="https://assets.skor.id/crop/136x76:1228x690/x/photo/2020/10/05/1364398555.jpg" alt="main" className='w-full h-full aspect-video object-cover object-top app-animation-translate-right-start' />
        </div>
        <div className='bg-app-blue-2 text-white px-4 py-6 md:py-12 md:px-10 lg:px-20 flex items-center justify-center sm:justify-start'>
          <p className='text-lg sm:text-xl md:text-2xl font-semibold sm:font-bold max-w-xs text-center sm:text-left app-animation-translate-start'>{t('firstHighlightText')}</p>
        </div>
      </section>

      <section className='bg-app-grey'>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportConsultTitle2')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{esportsConsulting.generalBrands}</div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('prPublishingTitle2')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{esportsConsulting.gamePeripheralBrands}</div>
        </div>
      </section>

      <section className='bg-app-grey'>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('ourRecentEvents')}
          </p>
          <div className='grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8 py-4'>

            {publicPosts?.filter(post => post?.tag?.toLowerCase() === "event").slice(0, 6).map((item, i) => (
              <div key={i} className='space-y-4'>
                <img className="w-full aspect-[4/3] object-cover border" src={item.img_cover} alt={item.title} />
                <p className='font-bold text-sm sm:text-lg'>{item.title}</p>
                <p className='font-semibold text-xs sm:text-base text-app-blue-3 truncate'>{item.summary}</p>
                <a href={`article/${item?.id}`} className='flex items-center gap-2 text-xs sm:text-sm '>
                  <p>{t('learnMore')}</p>
                  <ArrowRightIcon className='h-2 w-2 sm:h-3 sm:w-3 fill-black' />
                </a>
              </div>
            ))}


          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default Home