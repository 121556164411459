import React from 'react'

function ReportTableRow({
	contents
}) {
	return (
		<tbody>
			{
				contents && contents.length > 0 ? 
					contents?.map(content => (
						<tr className='border-b-2 border-black'>
							<td className='py-4 w-1/2'>
								{content?.file_name}
							</td>
							{content?.id_file_url && content?.id_file_url !== '' ? (
								<>
									<td className='py-4'>
										<a className='flex flex-row justify-center font-bold text-red-700' href={content?.id_file_url} target="_blank" rel="noopener noreferrer">View ID</a>
									</td>
									{/* <td className='py-4'>
																		<a className='flex flex-row justify-center font-bold text-red-700' href={content?.id_file_url} download={`${content?.filename}-ID.pdf`}>Download ID&nbsp;<DownloadIcon color="text-red-700" /></a>
																</td> */}
								</>
							) : (
								<>
									<td className='py-4'>
										<p className='flex flex-row justify-center font-bold'>No ID Files Available</p>
									</td>
								</>
							)}
							{content?.en_file_url && content?.en_file_url !== '' ? (
								<>
									<td className='py-4'>
										<a className='flex flex-row justify-center font-bold text-red-700' href={content?.en_file_url} target="_blank" rel="noopener noreferrer">View EN</a>
									</td>
									{/* <td className='py-4'>
																		<a className='flex flex-row justify-center font-bold text-red-700' href={content?.en_file_url} download={`${content?.filename}-EN.pdf`}>Download EN&nbsp;<DownloadIcon color="text-red-700" /></a>
																</td> */}
								</>
							) : (
								<>
									<td className='py-4'>
										<p className='flex flex-row justify-center font-bold'>No EN Files Available</p>
									</td>
								</>
							)}
						</tr>
					))
				:
					<p className='text-center'>No data available</p>
			}
		</tbody>
	)
}

export default ReportTableRow