import React from 'react'
import { useTranslation } from 'react-i18next';

import item1 from "assets/images/item-1.png"

import { about } from 'utils/constants/data'
import Newsletter from 'components/layout/Newsletter'

function RemunerationCommittee() {
  const { t } = useTranslation();

  return (
    <div>
      <section>
        <div className='relative'>
          <img src={item1} alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-bottom' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('remunerationBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('remunerationTitle')}
          </p>
          {/* <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{about.whyUs}</div> */}
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default RemunerationCommittee