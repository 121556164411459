import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

// Components
import Pagination from 'components/layout/Pagination'

// Apis
import useFetch from 'utils/hooks/useFetch'
import { GET_TOURNAMENT_PARTICIPANTS } from 'api';

// Icons
import SearchIcon from 'assets/icons/SearchIcon'

function TournamentTeamList({ tournament, schedules }) {
  const { t } = useTranslation();

  const { data: participants, fetch: getTournamentParticipants } = useFetch(GET_TOURNAMENT_PARTICIPANTS)

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTournamentTeam, setSearchTournamentTeam] = useState('');
  const [filterWaveValue, setFilterWaveValue] = useState('');
  const [recordsPerPage, setRecordsPerPage] = useState(8);
  const [numberOfPages, setNumberOfPages] = useState(0)

  const goToNextPage = () => {
    if (currentPage !== numberOfPages)
      setCurrentPage(currentPage + 1)
  }

  const goToPreviousPage = () => {
    if (currentPage !== 1)
      setCurrentPage(currentPage - 1)
  }

  const goToPageNumber = (number) => {
    setCurrentPage(number)
  }

  const handleScheduleName = (schedules, id) => {
    const scheduleName = schedules && schedules.length > 0 && schedules.filter(schedule => schedule.id === id)
    const scheduleTitle = scheduleName && scheduleName[0] && scheduleName[0].title
    return scheduleTitle
  }

  useEffect(() => {
    if (tournament && tournament.id) {
      getTournamentParticipants({
        params: {
          tournament_id: tournament.id
        },
        data: {
          limit: recordsPerPage,
          offset: recordsPerPage * currentPage - recordsPerPage,
          sort: ['createdAt', 'DESC'],
          search: searchTournamentTeam,
          schedule_id: filterWaveValue
        }
      }).then((data) => {
        if (data && data.count) {
          setNumberOfPages(Math.ceil(data.count / recordsPerPage));
        }
      });
    }
  }, [
    tournament,
    getTournamentParticipants,
    searchTournamentTeam,
    filterWaveValue,
    currentPage
  ])

  const dummyGroupPicture = 'https://cdn.pixabay.com/photo/2017/11/10/05/46/group-2935521_640.png'

  return (
    <section className='mx-8 sm:mx-12'>
      <div className='max-w-6xl mx-auto my-6 px-4 py-6 sm:px-12 sm:py-8 sm:my-8 space-y-8 border border-2 border-black rounded-lg'>
        <p className='text-xl sm:text-3xl uppercase font-bold text-center'>
          {t('checkYourTeam')}
        </p>
        <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
          <div className="flex">
            <div className="relative">
              <input value={searchTournamentTeam} onChange={({ target }) => setSearchTournamentTeam(target.value)} className="w-60 sm:w-80 lg:w-96 px-4 py-3 text-xs sm:text-sm bg-transparent border-2 rounded-full border-black focus:outline-none" placeholder={t('searchTeamPlaceholder')} />
              <button disabled className="absolute top-0 end-0 px-4 py-3 text-xs sm:text-sm font-medium h-full text-white bg-black rounded-r-full focus:outline-none">
                <span><SearchIcon className="h-4 w-4 fill-white" /></span>
              </button>
            </div>
          </div>
          {
            tournament && tournament.is_wave && (
              <div className="flex flex-row justify-center items-center">
                {/* <label htmlFor="small" className="text-xs sm:text-sm font-medium">{t('sortBy')}</label> */}
                <select id="small" value={filterWaveValue} onChange={({ target }) => setFilterWaveValue(target.value)} className="text-center px-2 py-3 text-xs sm:text-sm bg-transparent border border-black border-2 rounded-full focus:outline-none">
                  <option value="" selected>All Waves</option>
                  {schedules && schedules.length > 0 && schedules?.map((option, i) => (
                    <option key={i} value={option.id}>{option.title}</option>
                  ))}
                </select>
              </div>
            )
          }
        </div>
        {
          participants && participants.result && participants.result.length > 0 && (
            <div className='grid grid-cols-2 sm:grid-cols-4 justify-items-center gap-4 md:gap-6'>
              {participants && participants.result.map((record, i) => {
                return (
                  <div key={i} className="h-[150px] sm:h-[100px] w-full flex flex-col sm:flex-row items-center border-2 border-slate-300 rounded-lg">
                    <img className="object-cover rounded-lg w-full h-1/2 sm:h-full sm:w-1/2" src={record.team_logo_url ? record.team_logo_url : dummyGroupPicture} alt="" />
                    <div className="w-full flex flex-col justify-center ml-2 pt-2 sm:pt-0">
                      <h5 className="text-xs sm:text-base font-bold">{record.team_name}</h5>
                      {
                        tournament && tournament.is_wave && (
                          <p className="text-xs sm:text-base">{handleScheduleName(schedules, record.tournament_schedule_id)}</p>
                        )
                      }
                    </div>
                  </div>
                )
              })}
            </div>
          )
        }
        {
          participants && participants.result && participants.result.length === 0 && (
            <p className='text-sm sm:text-base font-bold text-app-blue-3 text-center'>
              {t('notAvailable')}
            </p>
          )
        }
        <div hidden={participants && participants.result && participants.result.length === 0}>
          <Pagination
            numberOfPages={numberOfPages}
            currentPage={currentPage}
            goToPageNumber={goToPageNumber}
            previousPage={goToPreviousPage}
            nextPage={goToNextPage}
          />
        </div>
      </div>
    </section>
  )
}

export default TournamentTeamList