import React, { useEffect, useState } from 'react'
import Navbar from './Navbar'
import Footer from './Footer'
import Menu from 'components/menu/Menu'
// import SupportChat from 'components/support_chat/SupportChat'
import useAnimation from 'utils/hooks/useAnimation'
import CookieConsent from 'components/modal/cookie_consent/CookieConsent'
import Cookies from 'js-cookie'
import i18n from 'i18next'

function MainContainer({ children }) {
    const [isMenuOpen, setMenuOpen] = useState(false)
    const [isOpenCookieConsent, setOpenCookieConsent] = useState(true);

    const [isEnglish, setIsEnglish] = useState(true)

    useEffect(() => {
        const selectedLanguage = localStorage.getItem("language")
        if (selectedLanguage === "en") {
            setIsEnglish(true)
            i18n.changeLanguage("en")
        }
        if (selectedLanguage === "id") {
            setIsEnglish(false)
            i18n.changeLanguage("id")
        }
    }, []);

    const handleSwitchLanguage = () => {
        setIsEnglish((prev) => !prev)
        if (isEnglish === true) {
            localStorage.setItem("language", "id")
            i18n.changeLanguage("id")
        }
        if (isEnglish === false) {
            localStorage.setItem("language", "en")
            i18n.changeLanguage("en")
        }
        window.scrollTo(0, 0);
        window.location.reload(true)
    }

    useAnimation()

    return (
        <div>
            <Navbar
                openMenu={() => setMenuOpen(true)}
            />
            <div className="min-h-screen">
              {children}
            </div>
            <Footer
                isEnglish={isEnglish}
                handleSwitchLanguage={handleSwitchLanguage}
            />
            <Menu
                isOpen={isMenuOpen}
                onClose={() => setMenuOpen(false)}
            />
            {/* <SupportChat /> */}
            {isOpenCookieConsent && window.location.pathname !== "/privacy-policy" && Cookies.get('cookie_consent') !== "true" && (
                <CookieConsent setOpenCookieConsent={setOpenCookieConsent} />
            )}
        </div>
    )
}

export default MainContainer