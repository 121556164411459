import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

import Newsletter from 'components/layout/Newsletter'
import Pagination from 'components/layout/Pagination'

import useFetch from 'utils/hooks/useFetch'
import { GET_ALL_PUBLIC_POSTS } from 'api'

function Events() {
  const { data: publicPosts, fetch: getAllPublicPosts } = useFetch(GET_ALL_PUBLIC_POSTS)
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(12);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const currentRecords = publicPosts && publicPosts?.length > 0 && publicPosts?.filter(post => post?.tag?.toLowerCase() === "event")?.slice(indexOfFirstRecord, indexOfLastRecord);
  const numberOfPages = Math.ceil(publicPosts && publicPosts?.length > 0 && publicPosts?.filter(post => post?.tag?.toLowerCase() === "event")?.length / recordsPerPage)

  const goToNextPage = () => {
    if (currentPage !== numberOfPages)
      setCurrentPage(currentPage + 1)
    window.scrollTo(0, 250)
  }

  const goToPreviousPage = () => {
    if (currentPage !== 1)
      setCurrentPage(currentPage - 1)
    window.scrollTo(0, 250)
  }

  const goToPageNumber = (number) => {
    setCurrentPage(number)
    window.scrollTo(0, 250)
  }

  useEffect(() => {
    getAllPublicPosts();
  }, [getAllPublicPosts])

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/2747446/pexels-photo-2747446.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>
                {t('eventBannerText')}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='app-main-container space-y-8 app-animation-scale-start'>
          {
            currentRecords && currentRecords.length > 0 ? (
              <>
                <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center'>
                  {t('eventTitle')}
                </p>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6'>
                  {currentRecords && currentRecords.map((post, i) => {
                    return (
                      <a key={i} className='space-y-1 sm:space-y-2' href={`/article/${post.id}`}>
                        <img className='w-full aspect-square object-contain' src={post.img_cover} alt={post.title} />
                        <p className='text-sm sm:text-base font-bold'>{post.title}</p>
                        <p className='text-xs sm:text-sm truncate'>{post.summary}</p>
                        <p className='text-xs sm:text-sm text-app-grey-2'>{moment(post.createdAt).format("DD MMMM YYYY")}</p>
                      </a>
                    )
                  })}
                </div>
                <div>
                  <Pagination
                    numberOfPages={numberOfPages}
                    currentPage={currentPage}
                    goToPageNumber={goToPageNumber}
                    previousPage={goToPreviousPage}
                    nextPage={goToNextPage}
                  />
                </div>
              </>
            ) : (
              <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
                {t('notAvailable')}
              </p>
            )
          }
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default Events