import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useTranslation } from 'react-i18next';
import ToggleButton from 'components/button/ToggleButton';

const setCookie = (name, value, options) => {
    let secure = true;
    if (process.env.NODE_ENV === "development") secure = false;
    Cookies.set(name, value, { path: "/", secure, ...options });
};

const getCookie = (name) => {
    return Cookies.get(name)
}

function CookieConsent({
    setOpenCookieConsent
}) {
    const { t } = useTranslation();
    const [showSettings, setShowSettings] = useState(false)
    const [cookiesPreferences, setCookiesPreferences] = useState([
        {
            id: 1,
            name: "performance",
            enabled: true,
            title: t('cookiePreferenceTitle1'),
            description: t('cookiePreferenceDesc1')
        },
        {
            id: 2,
            name: "functional",
            enabled: true,
            title: t('cookiePreferenceTitle2'),
            description: t('cookiePreferenceDesc2')
        },
        {
            id: 3,
            name: "targeting",
            enabled: true,
            title: t('cookiePreferenceTitle3'),
            description: t('cookiePreferenceDesc3')
        },
    ])

    const handleOnChangeType = (name) => {
        setCookiesPreferences(prev => prev.map(item => {
            let temp = Object.assign({}, item);
            if (temp.name === name) {
                temp.enabled = !item.enabled;
            }
            return temp
        }))
    }

    const handleCancel = () => {
        if (showSettings) {
            setShowSettings(false)
        } else {
            setCookiesPreferences(prev => prev.map(item => {
                const value = getCookie(`cookie_${item.id}`)
                let temp = Object.assign({}, item);
                if (value) {
                    temp.enabled = value === "true" ? true : false;
                }
                return temp
            }))
            setShowSettings(true)
        }
    }

    const handleSave = () => {
        cookiesPreferences.forEach(pref => {
            setCookie(`cookie_${pref.id}`, pref.enabled ? "true" : "false")
        })
        setCookie('cookie_consent', true)
        setOpenCookieConsent(false)
        window.location.reload();
    }

    useEffect(() => {
        setCookiesPreferences(prev => prev.map(item => ({ ...item, enabled: true })))
    }, [])

    return (
        <div className='fixed top-0 bottom-0 right-0 left-0 z-50 flex justify-center items-end'>
            <div className={`flex flex-col fixed bg-white z-50 sm:mb-4 md:mb-20 sm:mx-4 ${showSettings ? "max-w-4xl" : "max-w-2xl"} max-h-[90vh] text-left py-4 sm:px-12 sm:py-6 space-y-4 sm:space-y-6`}>
                <p className='font-bold text-lg md:text-xl px-4 sm:px-0'>{showSettings ? t('cookieConsentChoice1') : t('cookieConsentChoice2')}</p>
                {showSettings ? (
                    <div className='flex-1 overflow-y-auto px-4 sm:px-0'>
                        <div className='mb-4'>
                            <div className='flex justify-between'>
                                <p className='text-sm sm:text-base font-bold mb-2'>{t('essentialCookie')}</p>
                                <p className='text-xs sm:text-sm text-app-blue font-light'>{t('alwaysActiveCookie')}</p>
                            </div>
                            <p className='text-xs sm:text-sm'>
                                {t('cookieConsentDesc1')}
                                {t('cookieConsentDesc2')}
                            </p>
                        </div>
                        {cookiesPreferences.map((pref, i) => (
                            <div className='mb-4'>
                                <div className='flex justify-between'>
                                    <p className='text-sm sm:text-base font-bold mb-2'>{pref.title}</p>
                                    <ToggleButton
                                        name={pref.name}
                                        checked={pref.enabled}
                                        onChange={() => handleOnChangeType(pref.name)}
                                    />
                                </div>
                                <p className='text-xs sm:text-sm'>
                                    {pref.description}
                                </p>
                            </div>
                        ))}
                    </div>
                ) : (
                    <div className='flex-1 overflow-y-auto px-4 sm:px-0'>
                        <p className='text-xs md:text-sm'>
                            {t('cookieConsentDesc3')}&nbsp;
                            {t('cookieConsentDesc4')}&nbsp;
                            {t('cookieConsentDesc5-1')} "<b>{t('acceptAll')}</b>", {t('cookieConsentDesc5-2')}&nbsp;
                            {t('cookieConsentDesc6-1')} "<b>{t('manageCookie')}</b>" {t('cookieConsentDesc6-2')}
                        </p>
                        <p className='text-xs md:text-sm'>
                            {t('cookieConsentDesc7')}&nbsp;
                            <a target="_blank" href="privacy-policy"><b>{t('privacyPolicy')}</b></a>.
                        </p>
                    </div>
                )}

                <div className='flex gap-4 max-w-xl mx-auto w-full px-4 sm:px-0 '>
                    <button className='flex-1 text-xs md:text-sm border border-app-blue p-2 text-app-blue' onClick={handleCancel}>{showSettings ? t('cancelText') : t('manageCookie')}</button>
                    <button className='flex-1 text-xs md:text-sm bg-app-blue text-white p-2' onClick={handleSave}>{showSettings ? t('saveText') : t('acceptAll')}</button>
                </div>
            </div>
            <div className='fixed top-0 bottom-0 right-0 left-0 z-40 bg-app-grey/50 blur-lg' />
        </div>
    )
}

export default CookieConsent