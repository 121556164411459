import React from 'react'
import { useTranslation } from 'react-i18next';

import Newsletter from 'components/layout/Newsletter'

function OurTeam() {
    const { t } = useTranslation();

    const people = [
        {
            image: 'https://media-assets-ggwp.s3.ap-southeast-1.amazonaws.com/2022/09/ceo-alter-ego-1.jpg',
            name: "Delwyn Sukamto",
            position: t('ourTeamPosition1'),
        },
        {
            image: 'https://media.licdn.com/dms/image/D5603AQHJyrMSPcsprg/profile-displayphoto-shrink_800_800/0/1644304656761?e=1705536000&v=beta&t=BP2v79B9QQbsp1Qstlw5KSdmzn0QNxggPnQJgs7zMpM',
            name: "Rheinhard Brain",
            position: t('ourTeamPosition2'),
        }
    ]

    return (
        <div>
            <section>
                <div className='relative'>
                    <img src="https://images.pexels.com/photos/207896/pexels-photo-207896.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
                    <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
                        <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
                            <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('ourTeamBannerText')}</p>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='app-main-container space-y-8'>
                    <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
                        {t('ourTeamTitle')}
                    </p>
                    <div className='grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4'>
                        {people.map((item, i) => (
                            <div key={i} className='space-y-2 app-animation-scale-start'>
                                <img src={item.image} alt={item.name} className='h-48 lg:h-72 rounded-t-2xl object-cover' />
                                <p className='text-sm sm:text-base font-bold'>{item.name}</p>
                                <p className='text-xs sm:text-sm text-app-blue-3'>{item.position}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default OurTeam