import React from 'react'
import { useTranslation } from 'react-i18next';

import Newsletter from 'components/layout/Newsletter'
import ImgGallery from 'components/layout/ImageGallery';

import { aeImages } from 'utils/constants/data';

function EsportsTeam() {
  const { t } = useTranslation();

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://assets.skor.id/crop/136x76:1228x690/x/photo/2020/10/05/1364398555.jpg" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('eSportTeamBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportTeamTitle1')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>
            <div className="py-4">
              <ImgGallery images={aeImages} />
            </div>
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default EsportsTeam