import React from 'react'
import { useTranslation } from 'react-i18next';

import AnalyzeIcon from "assets/icons/AnalyzeIcon"
import PlanIcon from "assets/icons/PlanIcon"

import Newsletter from 'components/layout/Newsletter'

function WhyUs() {
  const { t } = useTranslation();

  const whyUsTopContent = [
    {
      icon: AnalyzeIcon,
      title: t('whyUsTopContentTitle1'),
      description: t('whyUsTopContentDesc1')
    },
    {
      icon: PlanIcon,
      title: t('whyUsTopContentTitle2'),
      description: t('whyUsTopContentDesc2')
    }
  ]

  const whyUsSecondContent = [
    {
      icon: AnalyzeIcon,
      title: t('whyUsSecondContentTitle1'),
      description: t('whyUsSecondContentDesc1')
    },
    {
      icon: PlanIcon,
      title: t('whyUsSecondContentTitle2'),
      description: t('whyUsSecondContentDesc2')
    },
    {
      icon: AnalyzeIcon,
      title: t('whyUsSecondContentTitle3'),
      description: t('whyUsSecondContentDesc3')
    },
    {
      icon: PlanIcon,
      title: t('whyUsSecondContentTitle4'),
      description: t('whyUsSecondContentDesc4')
    },
    {
      icon: AnalyzeIcon,
      title: t('whyUsSecondContentTitle5'),
      description: t('whyUsSecondContentDesc5')
    },
    {
      icon: PlanIcon,
      title: t('whyUsSecondContentTitle6'),
      description: t('whyUsSecondContentDesc6')
    },
    {
      icon: AnalyzeIcon,
      title: t('whyUsSecondContentTitle7'),
      description: t('whyUsSecondContentDesc7')
    }
  ]

  const ourServices = [
    {
      id: 1,
      title: t('whyUsServicesTitle1'),
      link_url: '/esports-consulting',
      image_url: 'https://images.pexels.com/photos/3165335/pexels-photo-3165335.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
      id: 2,
      title: t('whyUsServicesTitle2'),
      link_url: '/esports-eo',
      image_url: 'https://storage.googleapis.com/nizerteklintasbumi/logo/sl-logo.jpeg'
    },
    {
      id: 3,
      title: t('whyUsServicesTitle3'),
      link_url: '/production-house',
      image_url: 'https://images.pexels.com/photos/66134/pexels-photo-66134.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
      id: 4,
      title: t('whyUsServicesTitle4'),
      link_url: '/digital-marketing',
      image_url: 'https://images.pexels.com/photos/5678243/pexels-photo-5678243.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
    },
    {
      id: 5,
      title: t('whyUsServicesTitle5'),
      link_url: '/esports-team',
      image_url: 'https://assets.skor.id/crop/136x76:1228x690/x/photo/2020/10/05/1364398555.jpg'
    }
  ]

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/6125330/pexels-photo-6125330.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('whyUsBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-8'>
            {whyUsTopContent.map((step, i) => {
              const Icon = step.icon
              return (
                <div key={i} className='space-y-4'>
                  <div className='space-y-2 app-animation-translate-start'>
                    <Icon />
                    <p className='font-bold uppercase text-sm md:text-base'>{step.title}</p>
                  </div>
                  <p className='text-sm md:text-base app-animation-translate-start'>{step.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('whyUsTitle1')}
          </p>
          <div className='app-main-container grid grid-cols-2 sm:grid-cols-3 gap-8'>
            {whyUsSecondContent.map((step, i) => {
              const Icon = step.icon
              return (
                <div key={i}>
                  <div className='space-y-2 app-animation-translate-start'>
                    <Icon />
                    <p className='font-bold uppercase text-sm md:text-base'>{step.title}</p>
                  </div>
                  <p className='text-sm md:text-base app-animation-translate-start'>{step.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('whyUsTitle2')}
          </p>
          <div className='grid grid-cols-2 sm:grid-cols-3 gap-8 py-8 place-content-center'>
            {
              ourServices && ourServices.map((item, i) => {
                return (
                  <div className='space-y-4 text-center text-xs sm:text-base'>
                    <div className="h-24 sm:h-48">
                      <img className='app-animation-scale-start object-contain w-full h-full' src={item && item.image_url} alt='mornington' />
                    </div>
                    <p className='text-app-blue-3 font-semibold app-animation-translate-start'>{item && item.title}</p>
                  </div>
                )
              })
            }
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default WhyUs