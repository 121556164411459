import React from 'react'
import { useTranslation } from 'react-i18next';

function SupportingProfessional() {
  const { t } = useTranslation();

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/9072322/pexels-photo-9072322.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-bottom object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('supportingProfessionalBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start mb-12'>
            {t('supportingProfessionalsTitle')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base mx-auto'>
            <p className='font-bold uppercase text-sm md:text-base pb-4'>{t('publicAccountingFirm')}</p>
            <hr />
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 my-5'>
              <div>
                <p className='text-sm md:text-base app-animation-translate-start pb-4'>
                  <b><a target='_blank' href='https://www.moore-global.com/locations/mirawati-sensi-idris-jakarta'>Mirawati Sensi Idris <br /> (Member firm of Moore Stephens International Limited)</a></b><br /><br />
                  {t('publicAccountingFirmDesc')}
                </p>
              </div>
              <div>
                <p className='text-sm md:text-base app-animation-translate-start pb-4'>
                  <b>{t('officeLocation')}</b><br /><br />
                  Intiland Tower, 7th Floor<br />
                  Jl. Jend. Sudirman Kav. 32<br />
                  Jakarta 10220, Indonesia
                </p>
              </div>
            </div>
            <p className='font-bold uppercase text-sm md:text-base pb-4'>{t('legalConsultingFirm')}</p>
            <hr />
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 my-5'>
              <div>
                <p className='text-sm md:text-base app-animation-translate-start pb-4'>
                  <b><a target='_blank' href='https://paralegal.id/kantor-hukum/law-office-anra-partners/'>ANRA and Partners</a></b><br /><br />
                  {t('legalConsultingFirmDesc')}
                </p>
              </div>
              <div>
                <p className='text-sm md:text-base app-animation-translate-start pb-4'>
                  <b>{t('officeLocation')}</b><br /><br />
                  Tempo Scan Tower Lt.32<br />
                  Jl. H.R Rasuna Said Kav. 3-4<br />
                  Jakarta 12950, Indonesia
                </p>
              </div>
            </div>
            <p className='font-bold uppercase text-sm md:text-base pb-4'>{t('shareRegistrar')}</p>
            <hr />
            <div className='grid grid-cols-1 sm:grid-cols-2 gap-8 my-5'>
              <div>
                <p className='text-sm md:text-base app-animation-translate-start pb-4'>
                  <b><a target='_blank' href='https://bimaregistra.co.id/'>PT. Bima Registra</a></b><br /><br />
                  {t('shareRegistrarDesc')}
                </p>
              </div>
              <div>
                <p className='text-sm md:text-base app-animation-translate-start pb-4'>
                  <b>{t('officeLocation')}</b><br /><br />
                  Satrio Tower Lantai 9A2<br />
                  Jl, Prof. Dr. Satrio Blok C4<br />
                  Jakarta 12950 , Indonesia
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default SupportingProfessional