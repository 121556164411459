import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'

import Newsletter from 'components/layout/Newsletter';
import { GET_POST_DETAILS } from 'api';
import useFetch from 'utils/hooks/useFetch';

function PortfolioDetails() {

    const { id } = useParams();

    const { data: post, fetch: getPost } = useFetch(GET_POST_DETAILS)

    useEffect(() => {
        getPost({
            params: {
                id
            }
        });
    }, [getPost, id])

    return (
        <div className='space-y-4'>
            <section>
                <img className="w-full max-h-[65vh] object-contain border" src={post?.response?.img_cover} alt={post?.response?.title} />
            </section>

            <section>
                <div className='app-main-container space-y-4'>
                    <p className='text-lg sm:text-xl font-bold '>{post?.response?.title}</p>
                    <div className='text-sm sm:text-base font-medium'>{post?.response?.summary}</div>
                    <div dangerouslySetInnerHTML={{ __html: post?.response?.content }} />
                </div>
            </section>
            {/* <Newsletter /> */}
        </div>
    )
}

export default PortfolioDetails