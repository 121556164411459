import React from 'react'
import { useTranslation } from 'react-i18next';

import Newsletter from 'components/layout/Newsletter'
import ImgGallery from 'components/layout/ImageGallery';

import PlanIcon from 'assets/icons/PlanIcon';

import {
  phClientsLogo,
  ottContentBrand,
  phVideoBanner,
  phYoutubeVideoLinks,
  phGdriveVideoLinks,
  phStudioImages,
  phStudio2Images,
  phDigitalAssetImages
} from 'utils/constants/data';

function ProductionHouse() {
  const { t } = useTranslation();

  const phSecondContent = [
    {
      icon: PlanIcon,
      title: t('productionHouseHighlight1'),
      description: t('productionHouseHighlightDesc1')
    },
    {
      icon: PlanIcon,
      title: t('productionHouseHighlight2'),
      description: t('productionHouseHighlightDesc2')
    },
    {
      icon: PlanIcon,
      title: t('productionHouseHighlight3'),
      description: t('productionHouseHighlightDesc3')
    },
    {
      icon: PlanIcon,
      title: t('productionHouseHighlight4'),
      description: t('productionHouseHighlightDesc4')
    },
    {
      icon: PlanIcon,
      title: t('productionHouseHighlight5'),
      description: t('productionHouseHighlightDesc5')
    }
  ]

  const productionHouse = {
    description: (
      <>
        <p className='app-animation-translate-start text-justify'>
          {t('productionHouseDesc1')}
        </p>
        <br />
        <p className='app-animation-translate-start text-justify'>
          {t('productionHouseDesc2')}
        </p>
      </>
    ),
    secondContent: (
      <div className='app-main-container grid grid-cols-2 sm:grid-cols-3 gap-8'>
        {phSecondContent.map((step, i) => {
          const Icon = step.icon
          return (
            <div key={i}>
              <div className='space-y-2 app-animation-translate-start'>
                <Icon />
                <p className='font-bold uppercase text-sm md:text-base'>{step.title}</p>
              </div>
              <p className='text-sm md:text-base app-animation-translate-start '>{step.description}</p>
            </div>
          )
        })}
      </div>
    ),
    generalBrands: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          phClientsLogo && phClientsLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
      </div>
    ),
    cableTvBrands: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          ottContentBrand && ottContentBrand.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
      </div>
    ),
    showreels: (
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-8">
        {
          phVideoBanner && phVideoBanner.map((image) => {
            return (
              <a href={image.link_url} target="_blank" rel="noreferrer" className='app-animation-translate-start'>
                <div className="w-full h-32 sm:h-48 bg-black">
                  <img src={image.image_url} alt="video" className='w-full h-full object-contain' />
                </div>
                <p className='text-sm sm:text-base text-center text-app-blue-3 font-semibold pt-2'>
                  {image.title}
                </p>
              </a>
            )
          })
        }
        {
          phYoutubeVideoLinks && phYoutubeVideoLinks.map((video) => {
            return (
              <div className="app-animation-translate-start">
                <div className="w-full h-32 sm:h-48">
                  <iframe width="100%" height="100%" src={video.video_url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen="true"></iframe>
                </div>
                <p className='text-sm sm:text-base text-center text-app-blue-3 font-semibold pt-2'>
                  {video.title}
                </p>
              </div>
            )
          })
        }
        {
          phGdriveVideoLinks && phGdriveVideoLinks.map((video) => {
            return (
              <div className="app-animation-translate-start">
                <div className="w-full h-32 sm:h-48 app-animation-translate-start">
                  <iframe title="video" width="100%" height="100%" src={video.video_url} allow="autoplay" allowfullscreen="true"></iframe>
                </div>
                <p className='text-sm sm:text-base text-center text-app-blue-3 font-semibold pt-2'>
                  {video.title}
                </p>
              </div>
            )
          })
        }
      </div>
    ),
    studio: (
      <>
        <p className='app-animation-translate-start'>
          {t('productionHouseStudioDesc1')}
        </p>
        <br />
        <p className='app-animation-translate-start'>
          {t('productionHouseStudioDesc2')}
        </p>
        <br />
        <p className='app-animation-translate-start'>
          <b>{t('productionHouseStudioDesc3')}</b>
          <br />
          <div className="py-4">
            <ImgGallery images={phStudioImages} />
          </div>
        </p>
        <br />
        <p className='app-animation-translate-start'>
          <b>{t('productionHouseStudioDesc4')}</b>
          <br />
          <div className="py-4">
            <ImgGallery images={phStudio2Images} />
          </div>
        </p>
        <br />
        <p className='app-animation-translate-start'>
          <b>{t('productionHouseStudioDesc5')}</b>
          <br />
          <div className="py-4">
            <ImgGallery images={phDigitalAssetImages} />
          </div>
        </p>
      </>
    )
  }

  return (
    <div>
      <section>
        <div className='relative'>
          <img src='https://images.pexels.com/photos/2335048/pexels-photo-2335048.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('productionHouseBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('productionHouseHeadline1')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{productionHouse.description}</div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('productionHouseHeadline2')}
          </p>
          {productionHouse.secondContent}
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('productionHouseHeadline3')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{productionHouse.studio}</div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('productionHouseHeadline4')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{productionHouse.generalBrands}</div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('productionHouseHeadline5')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{productionHouse.cableTvBrands}</div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('productionHouseHeadline6')}
          </p>
          <div>
            {productionHouse.showreels}
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default ProductionHouse