import React from 'react'
import { useTranslation } from 'react-i18next';

function TournamentTerms({
  terms,
  bulletIcon,
  fontColor
}) {
  const { t } = useTranslation();

  return (
    <section>
      <div className={`max-w-7xl mx-auto px-4 py-6 sm:px-12 sm:py-8 space-y-8 text-${fontColor ? fontColor : "black"}`}>
        <p className='text-center text-xl sm:text-3xl font-bold'>
          {t('termsConditions')}
        </p>
        {terms && terms.length > 0 && terms.map((term, i) => {
          return (
            <div className='flex flex-row'>
              {bulletIcon ? (
                <img alt="bullet-icon" className='w-[25px] mt-1 mr-3 object-contain object-top' src={bulletIcon} />
              ) : (
                <p className='text-sm sm:text-base'>{i + 1}. &nbsp;</p>
              )}
              <div className='text-sm sm:text-base' dangerouslySetInnerHTML={{ __html: term && term.description_html }} />
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default TournamentTerms