import React from 'react'
import { useTranslation } from 'react-i18next';

import PlanIcon from "assets/icons/PlanIcon"
import Newsletter from 'components/layout/Newsletter'

function EsportsEo() {
  const { t } = useTranslation();

  const esportsEoSecondContent = [
    {
      icon: PlanIcon,
      title: t('eSportOrganizerSpecialize1'),
      description: t('eSportOrganizerSpecializeDesc1')
    },
    {
      icon: PlanIcon,
      title: t('eSportOrganizerSpecialize2'),
      description: t('eSportOrganizerSpecializeDesc2')
    },
    {
      icon: PlanIcon,
      title: t('eSportOrganizerSpecialize3'),
      description: t('eSportOrganizerSpecializeDesc3')
    },
    {
      icon: PlanIcon,
      title: t('eSportOrganizerSpecialize4'),
      description: t('eSportOrganizerSpecializeDesc4')
    },
    {
      icon: PlanIcon,
      title: t('eSportOrganizerSpecialize5'),
      description: t('eSportOrganizerSpecializeDesc5')
    },
    {
      icon: PlanIcon,
      title: t('eSportOrganizerSpecialize6'),
      description: t('eSportOrganizerSpecializeDesc6')
    }
  ]

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/9072320/pexels-photo-9072320.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-bottom' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('eSportOrganizerBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportOrganizerTitle1')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>
            <div className="app-animation-translate-start">
              <div className="py-10 flex justify-center items-center">
                <img src="https://storage.googleapis.com/nizerteklintasbumi/logo/sl-logo.jpeg" alt="supreme-league-logo" className="object-contain" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportOrganizerTitle2')}
          </p>
          <div className='app-main-container grid grid-cols-2 sm:grid-cols-3 gap-8'>
            {esportsEoSecondContent.map((step, i) => {
              const Icon = step.icon
              return (
                <div key={i}>
                  <div className='space-y-2 app-animation-translate-start'>
                    <Icon />
                    <p className='font-bold uppercase text-sm md:text-base'>{step.title}</p>
                  </div>
                  <p className='text-sm md:text-base app-animation-translate-start'>{step.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default EsportsEo