import React from 'react'
import { useTranslation } from 'react-i18next';
import Newsletter from 'components/layout/Newsletter'
import ImageGallery from 'react-image-gallery'

function About() {
  const { t } = useTranslation();

  const about = {
    description: (
      <>
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph1')}
        </p>
        <br />
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph2')}
        </p>
        <br />
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph3')}
        </p>
        <br />
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph4')}
        </p>
        <br />
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph5')}
        </p>
        <br />
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph6')}
        </p>
        <br />
        <p className='app-animation-translate-start text-justify'>
          {t('aboutUsParagraph7')}
        </p>
      </>
    ),
    our_role_img: [{
      original: 'https://storage.googleapis.com/nizerteklintasbumi/banner/Setup-Ecosystem_NLB.png',
      thumbnail: 'https://storage.googleapis.com/nizerteklintasbumi/banner/Setup-Ecosystem_NLB.png'
    }]
  }


  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/9072322/pexels-photo-9072322.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-bottom object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('aboutUsBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('aboutUsTitle1')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{about.description}</div>
        </div>
      </section>

      {/* <section className='bg-app-grey'>
        <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 items-center'>
          <img className='app-animation-translate-right-start' src={visionMission} alt="vision and mission" />
          <div className='space-y-4 app-animation-translate-start'>
            <div>
              <p className='text-sm sm:text-base md:text-lg uppercase font-bold'>Vision</p>
              <p className='text-sm sm:text-base md:text-lg font-medium'>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
            </div>
            <div>
              <p className='text-sm sm:text-base md:text-lg uppercase font-bold'>Mission</p>
              <p className='text-sm sm:text-base md:text-lg font-medium'>Lorem ipsum dolor sit amet lorem ipsum dolor sit amet</p>
            </div>
          </div>
        </div>
      </section> */}

      <section className='bg-app-grey'>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('aboutUsTitle2')}
          </p>
          <div>
            <ImageGallery items={about.our_role_img} showThumbnails={false} showPlayButton={false} />
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default About