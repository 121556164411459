import React from 'react'
import { useTranslation } from 'react-i18next';

import Newsletter from 'components/layout/Newsletter'
import ImgGallery from 'components/layout/ImageGallery';

import PlanIcon from 'assets/icons/PlanIcon';
import AnalyzeIcon from 'assets/icons/AnalyzeIcon';

import { marketingCatImages } from 'utils/constants/data';

function EventMarketing() {
  const { t } = useTranslation();

  const eventMarketingTopContent = [
    {
      icon: AnalyzeIcon,
      title: t('eventMarketingTitle1'),
      description: t('eventMarketingDesc1'),
    },
    {
      icon: PlanIcon,
      title: t('eventMarketingTitle2'),
      description: t('eventMarketingDesc2'),
    },
    {
      icon: PlanIcon,
      title: t('eventMarketingTitle3'),
      description: t('eventMarketingDesc3'),
    },
    {
      icon: PlanIcon,
      title: t('eventMarketingTitle4'),
      description: t('eventMarketingDesc4'),
    }
  ]

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/9072210/pexels-photo-9072210.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('eventMarketingBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eventMarketingTitle1')}
          </p>
          <div className='app-main-container space-y-4'>
            <div className='app-main-container grid grid-cols-1 sm:grid-cols-2 gap-8'>
              {eventMarketingTopContent.map((step, i) => {
                const Icon = step.icon
                return (
                  <div key={i} className='space-y-4'>
                    <div className='space-y-2 app-animation-translate-start'>
                      <Icon />
                      <p className='font-bold uppercase text-sm md:text-base'>{step.title}</p>
                    </div>
                    <p className='text-sm md:text-base app-animation-translate-start'>{step.description}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eventMarketingTitle2')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>
            <div className="py-4">
              <ImgGallery images={marketingCatImages} />
            </div>
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default EventMarketing