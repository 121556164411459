import React from 'react'
import { useTranslation } from 'react-i18next';

function CorporateSocialResponsibility() {
  const { t } = useTranslation();

  return (
    <div>
      <section>
        <div className='relative'>
          <img src='https://images.pexels.com/photos/2335048/pexels-photo-2335048.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('eventBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('corpSocialResp')}
          </p>
          {/* <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{description}</div> */}
          <div className="app-animation-translate-start">
            <div className="w-full h-80 sm:h-96 md:h-[40rem]">
              <iframe width="100%" height="100%" src="https://www.youtube.com/embed/vTJGydQHl8o?si=rEeJp4xBv8hDkCTc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
            </div>
            <p className='text-sm sm:text-base text-center text-app-blue-3 font-semibold pt-4'>
              Alter Ego x Alter Champs Charity: Peduli Cianjur!
            </p>
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default CorporateSocialResponsibility