import KeyboardArrowDownIcon from 'assets/icons/KeyboardArrowIcon'
import React from 'react'

function SelectInput({
  name,
  className,
  label,
  required,
  options,
  disabled,
  onChange,
  onBlur,
  error,
  value,
}) {
  return (
    <div className={`flex flex-col ${label ? "space-y-2" : ""} ${className}`}>
      {label && (
        <label
          htmlFor={name}
          className='font-bold flex text-sm sm:text-base'
        >
          <p>{label}</p>
          {required && (
            <span className='text-red-500'>*</span>
          )}
        </label>
      )}
      <div className='relative flex items-center overflow'>
        <select id={name} name={name} className='text-sm sm:text-base truncate appearance-none w-full border border-app-light-grey rounded-lg bg-transparent px-3 py-2' disabled={disabled} onChange={onChange} onBlur={onBlur}>
          {options?.map((option, i) => (
            <option key={i} value={option.id || option.text} selected={option.text === value}>{option.text}</option>
          ))}
        </select>
        <KeyboardArrowDownIcon
          className="absolute right-0 mr-3 fill-black h-3 w-3"
        />
      </div>
      {error && (
        <p className='text-xs text-red-500'>{error}</p>
      )}
    </div>
  )
}

export default SelectInput