import React from 'react'
import DownloadIcon from 'assets/icons/DownloadIcon'

function Table({
    headers,
    body
}) {
    return (
        <section className='m-5 p-3 flex flex-row justify-center'>
            <table className='table-auto lg:w-3/5 sm:w-full'>
                {headers && headers?.length > 0 && (
                    <thead>
                        <tr>
                            {headers?.map(header => (
                                <th>{header}</th>
                            ))}
                        </tr>
                    </thead>
                )}
                {body}
            </table>
        </section>
    )
}

export default Table