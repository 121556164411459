import React from 'react'

function NextIcon({
    width = "w-6",
    height = "h-6",
    color = "text-black"
}) {
    return (
        <svg class={`${width} ${height} ${color}`} aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
        </svg>
    )
}

export default NextIcon