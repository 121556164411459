import React from 'react'
import { useTranslation } from 'react-i18next';

import TextInput from 'components/input/TextInput'
import SelectInput from 'components/input/SelectInput';
import FileInput from 'components/input/FileInput';


function TournamentPlayerForm({
  textInputName1,
  valueInput1,
  errorInput1,
  textInputName2,
  valueInput2,
  errorInput2,
  selectName,
  valueSelectInput,
  errorSelectInput,
  fileInputName,
  valueFileInput,
  errorFileInput,
  webullInputName,
  valueWebullInput,
  errorWebullInput,
  gameUidInputName,
  valueGameUidInput,
  errorGameUidInput,
  required,
  onChangeInput,
  onChangeFileInput,
  tournament
}) {
  const { t } = useTranslation();

  return (
    <section>
      <div className='space-y-4'>
        <div className='space-y-4'>
          <TextInput
            name={textInputName1}
            label={t('tournamenPlayerFormLabel1')}
            required={required}
            placeholder={t('tournamenPlayerFormPlaceholder1')}
            value={valueInput1}
            onChange={onChangeInput}
            error={errorInput1}
          />
          <TextInput
            name={textInputName2}
            label={t('tournamenPlayerFormLabel2')}
            required={required}
            placeholder={t('tournamenPlayerFormPlaceholder2')}
            value={valueInput2}
            onChange={onChangeInput}
            error={errorInput2}
          />
          {
            tournament && tournament.with_game_uid && (
              <TextInput
                name={gameUidInputName}
                label={t('tournamenPlayerFormLabel6')}
                required={required}
                placeholder={t('tournamenPlayerFormPlaceholder6')}
                value={valueGameUidInput}
                onChange={onChangeInput}
                error={errorGameUidInput}
              />
            )
          }
          <SelectInput
            name={selectName}
            label={t('tournamenPlayerFormLabel3')}
            required={required}
            options={[
              { text: "Select one..." },
              { text: "Farm Lane" },
              { text: "Roaming" },
              { text: "Mid Lane" },
              { text: "Jungling" },
              { text: "Clash Lane" },
            ]}
            value={valueSelectInput}
            onChange={onChangeInput}
            error={errorSelectInput}
          />
          <FileInput
            name={fileInputName}
            label={t('tournamenPlayerFormLabel4')}
            required={required}
            value={valueFileInput}
            onChange={onChangeFileInput}
            error={errorFileInput}
          />
          {
            tournament && tournament.with_webull_ss && (
              <FileInput
                name={webullInputName}
                label={t('tournamenPlayerFormLabel5')}
                required={required}
                value={valueWebullInput}
                onChange={onChangeFileInput}
                error={errorWebullInput}
              />
            )
          }
        </div>
      </div>
    </section>
  )
}

export default TournamentPlayerForm
