import React from 'react'
import { useTranslation } from 'react-i18next';
import item1 from "assets/images/item-1.png"

import { AdvancedRealTimeChart, FundamentalData } from "react-ts-tradingview-widgets"

function StockInformation() {
  const { t } = useTranslation();

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://storage.googleapis.com/nizerteklintasbumi/banner/20230401_One%20PIECE%20Exhibition_Done.jpeg" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('stockInfoBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('stockInfoTitle')}
          </p>
          <div className='pt-5'>
            <FundamentalData 
              colorTheme="light" 
              symbol='NASDAQ:AAPL'
              isTransparent 
              height={800} 
              width="100%"
              // copyrightStyles={{
              //   parent: {
              //     display: 'none'
              //   }
              // }} 
            />
            <AdvancedRealTimeChart 
              theme="light" 
              symbol='NASDAQ:AAPL'
              height={window.screen.width < 500 ? 400 : 500} 
              width={window.screen.width * 0.02}
              allow_symbol_change={false}
              details={false}
              watchlist={false}
              hide_legend={true}
              hide_side_toolbar={true}
              hide_top_toolbar={true}
              show_popup_button={true}
              // copyrightStyles={{
              //   parent: {
              //     display: 'none'
              //   }
              // }} 
            />
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default StockInformation