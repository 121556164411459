import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from 'react-i18next';

import Pagination from 'components/layout/Pagination'

import useFetch from 'utils/hooks/useFetch'
import { GET_ALL_TOURNAMENTS } from 'api'

// Icon
import SearchIcon from 'assets/icons/SearchIcon'

function Tournaments() {
  const { data: tournaments, fetch: getAllTournaments } = useFetch(GET_ALL_TOURNAMENTS)
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [searchTournament, setSearchTournament] = useState('');
  const [sortValue, setSortValue] = useState('newest');
  const [recordsPerPage, setRecordsPerPage] = useState(8);
  const [numberOfPages, setNumberOfPages] = useState(0)

  const goToNextPage = () => {
    if (currentPage !== numberOfPages) {
      setCurrentPage(currentPage + 1)
    }
    window.scrollTo(0, 250)
  }

  const goToPreviousPage = () => {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1)
    }
    window.scrollTo(0, 250)
  }

  const goToPageNumber = (number) => {
    setCurrentPage(number)
    window.scrollTo(0, 250)
  }

  const sortQuery = (value) => {
    let query;
    if (value === "newest") {
      query = ['createdAt', 'DESC']
    }
    if (value === "oldest") {
      query = ['createdAt', 'ASC']
    }
    return query
  }

  useEffect(() => {
    getAllTournaments({
      data: {
        limit: recordsPerPage,
        offset: recordsPerPage * currentPage - recordsPerPage,
        sort: sortQuery(sortValue),
        search: searchTournament,
      }
    }).then((data) => {
      if (data && data.count) {
        setNumberOfPages(Math.ceil(data.count / recordsPerPage));
      }
    });
  }, [
    getAllTournaments,
    sortValue,
    searchTournament,
    currentPage
  ])

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/2747446/pexels-photo-2747446.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>
                {t('tournaments')}
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='max-w-7xl mx-auto px-4 py-6 sm:px-12 sm:py-8 space-y-8 app-animation-scale-start'>
          <div className='space-y-8'>
            <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center'>
              {t('tournamentList')}
            </p>
            <div className="flex flex-col sm:flex-row justify-center items-center gap-4">
              <div className="flex">
                <div className="relative">
                  <input value={searchTournament} onChange={({ target }) => setSearchTournament(target.value)} className="w-60 sm:w-80 px-4 py-3 text-xs sm:text-sm bg-transparent border-2 border-black focus:outline-none" placeholder={t('searchPlaceholder')} />
                  <button disabled className="absolute top-0 end-0 p-2.5 text-xs sm:text-sm font-medium h-full text-white bg-black border border-black focus:outline-none">
                    <span><SearchIcon className="h-4 w-4 fill-white" /></span>
                  </button>
                </div>
              </div>
              <div className="flex flex-row justify-center items-center">
                <label htmlFor="small" className="text-xs sm:text-sm font-medium dark:text-white">{t('sortBy')}</label>
                <select id="small" value={sortValue} onChange={({ target }) => setSortValue(target.value)} className="text-center px-2 py-3 text-xs sm:text-sm bg-transparent underline underline-offset-4 focus:outline-none">
                  <option value="newest" selected>{t('sortNewDates')}</option>
                  <option value="oldest">{t('sortOldDates')}</option>
                  {/* <option value="ascending-name">A - Z</option>
                      <option value="descending-name">Z - A</option> */}
                </select>
              </div>
            </div>
            {
              tournaments && tournaments.result && tournaments.result.length > 0 && (
                <div className='grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8'>
                  {tournaments && tournaments.result.map((post, i) => {
                    return (
                      <a key={i} className='space-y-1 sm:space-y-2' href={`tournament/details/${post.id}`}>
                        <img className='w-full aspect-square object-contain' src={post.image_url} alt={post.title} />
                        <p className='text-sm sm:text-base font-bold'>{post.title}</p>
                        {/* <p className='text-xs sm:text-sm truncate'>{post.summary}</p>
                        <p className='text-xs sm:text-sm text-app-grey-2'>{moment(post.createdAt).format("DD MMMM YYYY")}</p> */}
                      </a>
                    )
                  })}
                </div>
              )
            }
            {
              tournaments && tournaments.result && tournaments.result.length === 0 && (
                <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center'>
                  {t('notAvailable')}
                </p>
              )
            }
            <div hidden={tournaments && tournaments.result && tournaments.result.length === 0}>
              <Pagination
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                goToPageNumber={goToPageNumber}
                previousPage={goToPreviousPage}
                nextPage={goToNextPage}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default Tournaments