import React, { useRef, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useFormik } from "formik"
import * as Yup from "yup"


import TextAreaInput from 'components/input/TextAreaInput'
import TextInput from 'components/input/TextInput'
import Newsletter from 'components/layout/Newsletter'

import useFetch from 'utils/hooks/useFetch'
import { SEND_CONTACT_FORM } from 'api';

import ReCAPTCHA from "react-google-recaptcha"

function ContactUs() {
  const { t } = useTranslation();
  const { fetch: sendContactForm } = useFetch(SEND_CONTACT_FORM)
  const [formValues, setFormValues] = useState({})
  const [captchaSuccess, setCaptchaSuccess] = useState(false)
  const recaptchaRef = useRef(null)

  useEffect(() => {
    if (captchaSuccess && captchaSuccess != '' && formValues && formValues.full_name) {
      handleSubmitForm()
    }
  }, [captchaSuccess, formValues])

  const formik = useFormik({
    initialValues: {
      email_address: '',
      full_name: '',
      company_name: '',
      division: '',
      message: '',
    },
    validationSchema: Yup.object().shape({
      email_address: Yup.string().required('Please input your email address').email('Invalid email format'),
      full_name: Yup.string().required('Please input your full name'),
      company_name: Yup.string().required('Please input your company name'),
      division: Yup.string().required('Please input your division'),
      message: Yup.string().required('Please input your message'),
    }),
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      setFormValues(values)
      recaptchaRef.current.execute()
    },
  });

  const handleSubmitForm = async () => {
    const values = formValues
    const newContactFormSubmitted = await sendContactForm({
      data: {
        full_name: values.full_name,
        email_address: values.email_address,
        company_name: values.company_name,
        division: values.division,
        message: values.message
      }
    })
    if (newContactFormSubmitted.success) {
      window.location.reload()
    }
  }

  return (
    <div>
      <section>
        <div className='max-w-xl lg:max-w-6xl mx-auto px-4 py-4 sm:px-8 sm:py-8 space-y-6'>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
            <div>
              <p className='font-bold uppercase text-sm md:text-base pb-4'>{t('contactDetailTitle')}</p>
              <p className='text-sm md:text-base app-animation-translate-start pb-4'>
                <b>Supreme League Head Office</b><br />
                Tokopedia Care Tower Ground, 19th Floor.<br />
                Jl. Lkr. Luar Barat No.101, Rw. Buaya, Kecamatan <br />
                Cengkareng, Kota Jakarta Barat, Daerah Khusus Ibukota Jakarta <br />
                Ph. (+6221) 3971 2878 | E. marketing@nlb.co.id 
              </p>
              <p className='text-sm md:text-base app-animation-translate-start pb-4'>
                <b>Alterego Gaming House & Supreme League</b><br />
                Jl. Meruya Ilir Raya Ruko Bisnis Park Blok I<br />
                No. 1-2 Kembangan, Jakarta Barat, DKI Jakarta-11620
              </p>
            </div>
            <div className='space-y-4 app-animation-translate-start'>
              <div>
                <p className='text-xl sm:text-2xl font-bold app-animation-translate-start'>{t('contactFormText1')}</p>
                <p className='text-xl sm:text-2xl font-bold app-animation-translate-start'>{t('contactFormText2')}</p>
              </div>
              <div>
                <p className='text-base sm:text-lg font-semibold uppercase text-app-blue-3 app-animation-translate-start'>{t('contactFormTitle')}</p>
              </div>
              <div className='app-animation-translate-start space-y-4'>
                <TextInput
                  name="full_name"
                  label={t('contactFormLabel1')}
                  required
                  placeholder={t('contactFormPlaceholder1')}
                  value={formik.values.full_name}
                  onChange={formik.handleChange}
                  error={formik.errors.full_name}
                />
                <TextInput
                  name="email"
                  label={t('contactFormLabel2')}
                  required
                  placeholder={t('contactFormPlaceholder2')}
                  value={formik.values.email_address}
                  onChange={formik.handleChange}
                  error={formik.errors.email_address}
                />
                <TextInput
                  name="company_name"
                  label={t('contactFormLabel3')}
                  required
                  placeholder={t('contactFormPlaceholder3')}
                  value={formik.values.company_name}
                  onChange={formik.handleChange}
                  error={formik.errors.company_name}
                />
                <TextInput
                  name="division"
                  label={t('contactFormLabel4')}
                  required
                  placeholder={t('contactFormPlaceholder4')}
                  value={formik.values.division}
                  onChange={formik.handleChange}
                  error={formik.errors.division}
                />
                <TextAreaInput
                  name="message"
                  label={t('contactFormLabel5')}
                  required
                  placeholder={t('contactFormPlaceholder5')}
                  rows={8}
                  value={formik.values.message}
                  onChange={formik.handleChange}
                  error={formik.errors.message}
                />
                <button
                  onClick={formik.handleSubmit}
                  className='bg-black text-white rounded-md px-12 py-2 text-sm sm:text-base disabled:opacity-50'
                  disabled={
                    !formik.values.email_address ||
                    formik.values.email_address === '' ||
                    !formik.values.full_name ||
                    formik.values.full_name === '' ||
                    !formik.values.company_name ||
                    formik.values.company_name === '' ||
                    !formik.values.division ||
                    formik.values.division === '' ||
                    !formik.values.message ||
                    formik.values.message === ''
                  }
                >
                  {t('contactFormSubmitButton')}
                </button>
              </div>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey="6LdWEYApAAAAAPnqsDKIum86q-RizTy7dtTq2ghl"
                onChange={setCaptchaSuccess}
              />
            </div>
          </div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default ContactUs
