import React from 'react'
import { useTranslation } from 'react-i18next';

import Newsletter from 'components/layout/Newsletter'
import ImgGallery from 'components/layout/ImageGallery';

import {
  publishingPrClients,
  technoMediaPartnerLogo,
  gamingMediaPartnerLogo,
  generalMediaPartnerLogo,
  promotionalImages
} from 'utils/constants/data';

function PublishingPr() {
  const { t } = useTranslation();

  const publishingPr = {
    description: (
      <>
        <p className='app-animation-translate-start'>
          {t('prPublishingDesc1')}
        </p>
        <br />
        <p className='app-animation-translate-start'>
          {t('prPublishingDesc2')}
        </p>
      </>
    ),
    generalBrands: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          publishingPrClients && publishingPrClients.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
      </div>
    ),
    technoMediaBrands: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          technoMediaPartnerLogo && technoMediaPartnerLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
      </div>
    ),
    gamingMediaBrands: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          gamingMediaPartnerLogo && gamingMediaPartnerLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
      </div>
    ),
    generalMediaBrands: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          generalMediaPartnerLogo && generalMediaPartnerLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
      </div>
    ),
    promotionalItems: (
      <div className="py-4">
        <ImgGallery images={promotionalImages} />
      </div>
    )
  }


  return (
    <div>
      <section>
        <div className='relative'>
          <img src='https://images.pexels.com/photos/1577882/pexels-photo-1577882.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2' alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('prPublishingBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('prPublishingTitle1')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{publishingPr.description}</div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('prPublishingTitle2')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{publishingPr.generalBrands}</div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('prPublishingTitle3')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{publishingPr.technoMediaBrands}</div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('prPublishingTitle4')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{publishingPr.gamingMediaBrands}</div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('prPublishingTitle5')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{publishingPr.generalMediaBrands}</div>
        </div>
      </section>

      <section className="bg-app-grey">
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('prPublishingTitle6')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{publishingPr.promotionalItems}</div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default PublishingPr