import React from 'react'

function TextInput({
    name,
    label,
    required,
    placeholder,
    value,
    onChange,
    onBlur,
    error,
    disabled
}) {
    return (
        <div className='flex flex-col space-y-2'>
            <label
                htmlFor={name}
                className='font-bold flex text-sm sm:text-base'
            >
                <p>{label}</p>
                {required && (
                    <span className='text-red-500'>*</span>
                )}
            </label>
            <input
                id={name}
                className='border border-app-light-grey rounded-lg bg-transparent px-3 py-2 text-sm sm:text-base'
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                disabled={disabled}
            />
            {error && (
                <p className='text-xs text-red-400'>{error}</p>
            )}
        </div>
    )
}

export default TextInput