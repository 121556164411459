import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import Newsletter from 'components/layout/Newsletter'
import Pagination from 'components/layout/Pagination'

import useFetch from 'utils/hooks/useFetch'
import { GET_ALL_PUBLIC_POSTS } from 'api'


function Portfolio() {
  const { data: publicPosts, fetch: getAllPublicPosts } = useFetch(GET_ALL_PUBLIC_POSTS)
  const { t } = useTranslation();

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(12);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const currentRecords = publicPosts && publicPosts?.length > 0 && publicPosts?.filter(post => post?.tag?.toLowerCase() === "portfolio")?.slice(indexOfFirstRecord, indexOfLastRecord);
  const numberOfPages = Math.ceil(publicPosts && publicPosts?.length > 0 && publicPosts?.filter(post => post?.tag?.toLowerCase() === "portfolio")?.length / recordsPerPage)

  const goToNextPage = () => {
    if (currentPage !== numberOfPages)
      setCurrentPage(currentPage + 1)
    window.scrollTo(0, 250)
  }

  const goToPreviousPage = () => {
    if (currentPage !== 1)
      setCurrentPage(currentPage - 1)
    window.scrollTo(0, 250)
  }

  const goToPageNumber = (number) => {
    setCurrentPage(number)
    window.scrollTo(0, 250)
  }

  useEffect(() => {
    getAllPublicPosts();
  }, [getAllPublicPosts])

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/919734/pexels-photo-919734.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('portfolioBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-8'>
          {
            currentRecords && currentRecords.length > 0 ? (
              <>
                <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
                  {t('portfolioTitle')}
                </p>
                <div className='grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4'>
                  {currentRecords && currentRecords.map((item, i) => (
                    <a href={`portfolio/${item?.id}`} key={i} className='flex flex-col items-center gap-2'>
                      <img className='w-full aspect-[4/3] object-cover border' src={item.img_cover} alt={item.title} />
                      <p className='text-sm sm:text-base text-center text-app-blue-3 font-semibold'>
                        {item.title}
                      </p>
                    </a>
                  ))}
                </div>
                <div>
                  <Pagination
                    numberOfPages={numberOfPages}
                    currentPage={currentPage}
                    goToPageNumber={goToPageNumber}
                    previousPage={goToPreviousPage}
                    nextPage={goToNextPage}
                  />
                </div>
              </>
            ) : (
              <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
                {t('notAvailable')}
              </p>
            )
          }
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default Portfolio