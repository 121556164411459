import SearchIcon from 'assets/icons/SearchIcon'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

function Menu({
  isOpen,
  onClose,
}) {
  const { t } = useTranslation();
  const [isOpenDropdown, setIsOpenDropdown] = useState(null)

  const navigation = [
    {
      id: 1,
      label: t('aboutUs'),
      href: "/about",
      dropdownNav: [
        {
          id: 1,
          label: t('whoWeAre'),
          href: "/about"
        },
        {
          id: 2,
          label: t('achievements'),
          href: "/achievements"
        },
        {
          id: 3,
          label: t('supportingProfessionals'),
          href: "/supporting-professionals"
        },
        {
          id: 4,
          label: t('whyUs'),
          href: "/why-us"
        },
        {
          id: 5,
          label: t('ourCorePeople'),
          href: "/team"
        },
        {
          id: 6,
          label: t('ourCompanyStructure'),
          href: "/company-structure"
        },
        {
          id: 7,
          label: t('ourCompanyOwnership'),
          href: "/company-ownership"
        },
        {
          id: 8,
          label: t('ourLeadership'),
          href: "/leadership"
        }
      ]
    },
    // {
    //   id: 2,
    //   label: t('governance'),
    //   href: '/corporate-secretary',
    //   dropdownNav: [
    //     {
    //       id: 1,
    //       label: t('corporateSecretary'),
    //       href: '/corporate-secretary'
    //     },
    //     {
    //       id: 2,
    //       label: t('internalAudit'),
    //       href: '/internal-audit'
    //     },
    //     {
    //       id: 3,
    //       label: t('auditCommitee'),
    //       href: '/audit-committee'
    //     },
    //     {
    //       id: 4,
    //       label: t('remunerationCommitee'),
    //       href: '/remuneration-committee'
    //     },
    //     {
    //       id: 5,
    //       label: t('riskManagement'),
    //       href: '/risk-management'
    //     },
    //     {
    //       id: 6,
    //       label: t('ourPolicies'),
    //       href: '/policies'
    //     }
    //   ]
    // },
    {
      id: 2,
      label: t('investors'),
      href: '/annual-reports',
      dropdownNav: [
        {
          id: 1,
          label: t('annualReports'),
          href: '/annual-reports'
        },
        {
          id: 2,
          label: t('supportInstPro'),
          href: '/supporting-institutions'
        },
        {
          id: 3,
          label: t('financialReports'),
          href: '/financial-reports'
        },
        {
          id: 4,
          label: t('generalMeetShare'),
          href: '/general-meetings'
        },
        {
          id: 5,
          label: t('stockInfo'),
          href: '/stock-information'
        },
        // {
        //   id: 6,
        //   label: t('bondInfo'),
        //   href: '/bond-information'
        // },
        {
          id: 7,
          label: t('infoDisclosure'),
          href: '/information-disclosure'
        },
        {
          id: 8,
          label: t('prospectus'),
          href: '/prospectus'
        }
      ]
    },
    {
      id: 3,
      label: t('ourServices'),
      href: "/services/esports-consulting",
      dropdownNav: [
        {
          id: 1,
          label: t('eSportConsulting'),
          href: '/services/esports-consulting',
        },
        {
          id: 2,
          label: t('eSportEventOrganizer'),
          href: '/services/esports-eo'
        },
        {
          id: 3,
          label: t('prodHouse'),
          href: '/services/production-house'
        },
        {
          id: 4,
          label: t('publishingPR'),
          href: '/services/publishing-pr'
        },
        {
          id: 5,
          label: t('eventMarketing'),
          href: '/services/event-marketing'
        },
        {
          id: 6,
          label: t('digitalMarketing'),
          href: '/services/digital-marketing'
        },
        {
          id: 7,
          label: t('eSportTeam'),
          href: '/services/esports-team'
        }
      ]
    },
    {
      id: 4,
      label: t('ourPortfolio'),
      href: "/portfolio",
    },
    {
      id: 5,
      label: t('ourLatestEvents'),
      href: "/events"
    },
    {
      id: 6,
      label: t('tournaments'),
      href: "/tournaments"
    },
    {
      id: 7,
      label: t('corpSocialResp'),
      href: "/corporate-social-responsibility"
    },
    // {
    //   id: 7,
    //   label: t('management'),
    //   href: "/management",
    // },
    // {
    //   id: 8,
    //   label: t('sustainability'),
    //   href: "/",
    // },
    {
      id: 8,
      label: t('contactUs'),
      href: "/contact-us",
    },
  ]

  const handleOpenDropdown = (e, navId, haveDropdown) => {
    if (haveDropdown) {
      e.preventDefault()
      if (isOpenDropdown) {
        setIsOpenDropdown(null)
      } else {
        setIsOpenDropdown(navId || null)
      }
    }
  }

  return (
    <div className={`lg:hidden fixed top-0 bottom-0 left-0 right-0 bg-white/50 backdrop-blur-lg ${isOpen ? "inline-block" : "hidden"} z-50`}>
      <div className='app-nav-container h-full flex flex-col gap-8'>
        <div className='text-right font-bold text-sm md:text-base'>
          <button className='active:border-b-2 active:border-black transition-all' onClick={onClose}>Close</button>
        </div>
        <div className={`flex flex-col justify-between flex-1`}>
          <ul className='flex flex-col gap-8 font-medium text-sm md:text-base'>
            {
              navigation && navigation.map((nav) => {
                const navDropdown = nav && nav.dropdownNav
                const navPath = nav && nav.href
                const navLabel = nav && nav.label
                const navId = nav && nav.id
                const openDropdown = isOpenDropdown == navId
                const haveDropdown = navDropdown && navDropdown.length > 0
                return (
                  <li className="app-animation-translate-start">
                    <a href={navPath} onClick={(e) => handleOpenDropdown(e, navId, haveDropdown)}>{navLabel}</a>
                    {
                      navDropdown &&
                      navDropdown.length > 0 &&
                      openDropdown && (
                        <ul className='flex flex-col gap-2 font-medium py-2 px-4'>
                          {
                            navDropdown && navDropdown.map((dropdown) => {
                              const dropdownPath = dropdown && dropdown.href
                              const dropdownLabel = dropdown && dropdown.label
                              return (
                                <li>
                                  <a href={dropdownPath}>{dropdownLabel}</a>
                                </li>
                              )
                            })
                          }
                        </ul>
                      )
                    }
                  </li>
                )
              })
            }
          </ul>
        </div>
      </div>
    </div>
  )

}

export default Menu