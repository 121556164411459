export default function ({ noOfPlayers, withGameUid, withWebullSS }) {
  let players = []
  if (noOfPlayers) {
    for (let i = 0; i < noOfPlayers; i ++) {
      let playerObj = {}
      playerObj = {
        [`name_player_${i+1}`]: '',
        [`nickname_uid_player_${i+1}`]: '',
        [`role_player_${i+1}`]: '',
        [`identity_file_player_${i+1}`]: []
      }
      if (withGameUid) {
        playerObj[`game_uid_player_${i+1}`] = ''
      }
      if (withWebullSS) {
        playerObj[`webull_account_player_${i+1}`] = []
      }
      players.push(playerObj)
    }
  }
  return players
}