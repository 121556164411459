import React from 'react'

function FileInput({
  name,
  label,
  required,
  placeholder,
  value,
  onChange,
  onBlur,
  error,
  disabled
}) {
  return (
    <div className='flex flex-col space-y-2 mb-2'>
      <label
        htmlFor={name}
        className='font-bold flex text-sm sm:text-base'
      >
        <p>{label}</p>
        {required && (
          <span className='text-red-500'>*</span>
        )}
      </label>
      <input
        id={name}
        className='border border-app-light-grey rounded-lg bg-transparent px-3 py-2 text-sm sm:text-base'
        // value={value}
        onChange={onChange}
        disabled={disabled}
        type='file'
        accept='.png, .jpg, .jpeg'
      />
      <p class="text-left mt-1 text-sm text-gray-500 " id={name}>PNG, JPG or JPEG. Maximum file size is 5MB.</p>
      {error && (
        <p className='text-xs text-red-400'>{error}</p>
      )}
    </div>
  )
}

export default FileInput