import React from 'react'
import { useTranslation } from 'react-i18next';

import {
  generalBrandsLogo,
  gamingPeripheralsLogo,
  gamePublishersLogo,
  ottContentBrand,
  internetProvidersBrand
} from 'utils/constants/data'

import Newsletter from 'components/layout/Newsletter'

function EsportsConsulting() {
  const { t } = useTranslation();

  const esportsConsulting = {
    description: (
      <>
        <p className='app-animation-translate-start'>
          {t('eSportConsultDesc1')}
        </p>
        <br />
        <p className='app-animation-translate-start'>
          {t('eSportConsultDesc2')}
        </p>
        <br />
        <p className='app-animation-translate-start'>
          <b>{t('eSportConsultDesc3')}</b>
          <br />
          {t('eSportConsultDesc4')}
        </p>
        <br />
        <p className='app-animation-translate-start'>
          {t('eSportConsultDesc5')}
        </p>
        <br />
      </>
    ),
    generalBrands: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          generalBrandsLogo && generalBrandsLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
        <p><b>{t('manyMore')}</b></p>
      </div>
    ),
    gamePeripheralBrands: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          gamingPeripheralsLogo && gamingPeripheralsLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
        <p><b>{t('manyMore')}</b></p>
      </div>
    ),
    gamePublishersLogo: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          gamePublishersLogo && gamePublishersLogo.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
        <p><b>{t('manyMore')}</b></p>
      </div>
    ),
    ottContentsLogo: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          ottContentBrand && ottContentBrand.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
      </div>
    ),
    internetProvidersLogo: (
      <div className="grid grid-cols-4 gap-4 items-center place-items-center app-animation-translate-start">
        {
          internetProvidersBrand && internetProvidersBrand.map((logo) => {
            return <img src={logo && logo.image_url} className="h-24 m:w-12 object-contain" alt="client-logo" />
          })
        }
      </div>
    )
  }

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/6125330/pexels-photo-6125330.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('eSportConsultBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportConsultTitle1')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{esportsConsulting.description}</div>
        </div>
      </section>

      <section className='bg-app-grey'>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportConsultTitle2')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{esportsConsulting.generalBrands}</div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportConsultTitle3')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{esportsConsulting.gamePeripheralBrands}</div>
        </div>
      </section>

      <section className='bg-app-grey'>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportConsultTitle4')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{esportsConsulting.gamePublishersLogo}</div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportConsultTitle5')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{esportsConsulting.ottContentsLogo}</div>
        </div>
      </section>

      <section className='bg-app-grey'>
        <div className='app-main-container space-y-8'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('eSportConsultTitle6')}
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto'>{esportsConsulting.internetProvidersLogo}</div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default EsportsConsulting