import ImageGallery from 'react-image-gallery'

function ImgGallery ({ images }) {

  return (
    <ImageGallery items={images} />
  )

}

export default ImgGallery