import React from 'react'

function About() {
  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://images.pexels.com/photos/9072322/pexels-photo-9072322.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-bottom object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>404 Not Found</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4'>
          <p className='text-xl sm:text-2xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            Sorry the page you are looking for....
          </p>
          <div className='text-app-blue-3 text-sm sm:text-base max-w-2xl mx-auto text-center'>.. is not available (or built yet)</div>
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default About