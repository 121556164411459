import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

// Component
import Newsletter from 'components/layout/Newsletter'
import Table from 'components/layout/Table'
import ReportTableRow from 'components/table_row/ReportTableRow'
import Pagination from 'components/layout/Pagination'

// Data
import prospectusData from '../../assets/data/prospectus-reports.json'

function Prospectus() {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const currentRecords = prospectusData.slice(indexOfFirstRecord, indexOfLastRecord);
  const numberOfPages = Math.ceil(prospectusData.length / recordsPerPage)

  const goToNextPage = () => {
    if (currentPage !== numberOfPages)
      setCurrentPage(currentPage + 1)
    window.scrollTo(0, 250)
  }

  const goToPreviousPage = () => {
    if (currentPage !== 1)
      setCurrentPage(currentPage - 1)
    window.scrollTo(0, 250)
  }

  const goToPageNumber = (number) => {
    setCurrentPage(number)
    window.scrollTo(0, 250)
  }

  return (
    <div>
      <section>
        <div className='relative'>
          <img src="https://storage.googleapis.com/nizerteklintasbumi/banner/20230401_One%20PIECE%20Exhibition_Done.jpeg" alt="main" className='w-full max-h-40 sm:max-h-60 object-cover object-center' />
          <div className='absolute top-0 bottom-0 left-0 right-0 bg-black/40 text-white'>
            <div className='app-main-container flex flex-col justify-center items-center h-full gap-4 sm:gap-8'>
              <p className='text-lg sm:text-3xl max-w-md px-8 font-semibold sm:font-bold text-center app-animation-translate-start'>{t('prospectusBannerText')}</p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='app-main-container space-y-4 border-b-4 border-black lg:w-3/5 sm:w-full'>
          <p className='text-xl sm:text-2xl lg:text-3xl font-bold text-app-blue-3 text-center app-animation-translate-start'>
            {t('prospectusTitle')}
          </p>
        </div>
        <div>
          <Table
            body={<ReportTableRow contents={currentRecords} />}
          />
        </div>
        <div>
          {
            numberOfPages && numberOfPages > 0 && (
              <Pagination
                numberOfPages={numberOfPages}
                currentPage={currentPage}
                goToPageNumber={goToPageNumber}
                previousPage={goToPreviousPage}
                nextPage={goToNextPage}
              />
            )
          }
        </div>
      </section>

      {/* <Newsletter /> */}
    </div>
  )
}

export default Prospectus